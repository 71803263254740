/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterDto { 
    password: string;
    email: string;
    firstName: string;
    lastName: string;
    /**
     * Available roles are designer, homeowner
     */
    role: RegisterDto.RoleEnum;
}
export namespace RegisterDto {
    export type RoleEnum = 'designer' | 'homeowner';
    export const RoleEnum = {
        Designer: 'designer' as RoleEnum,
        Homeowner: 'homeowner' as RoleEnum
    };
}


