/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttributeTypeDto { 
    name: string;
    valueType: AttributeTypeDto.ValueTypeEnum;
}
export namespace AttributeTypeDto {
    export type ValueTypeEnum = 'string' | 'number' | 'boolean' | 'color';
    export const ValueTypeEnum = {
        String: 'string' as ValueTypeEnum,
        Number: 'number' as ValueTypeEnum,
        Boolean: 'boolean' as ValueTypeEnum,
        Color: 'color' as ValueTypeEnum
    };
}


