/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderDelivery } from './order-delivery';
import { Return } from './return';
import { User } from './user';
import { OrderItem } from './order-item';
import { OrderPayment } from './order-payment';


export interface Order { 
    id: number;
    created: string;
    updated: string;
    user?: User;
    items: Array<OrderItem>;
    status: Order.StatusEnum;
    delivery: OrderDelivery;
    payment: OrderPayment;
    fullName: string;
    contactEmail: string;
    contactPhone: string;
    message?: string;
    return?: Return;
}
export namespace Order {
    export type StatusEnum = 'pending' | 'failed' | 'confirmed' | 'open' | 'cancelled' | 'delivered' | 'refunded';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Failed: 'failed' as StatusEnum,
        Confirmed: 'confirmed' as StatusEnum,
        Open: 'open' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        Delivered: 'delivered' as StatusEnum,
        Refunded: 'refunded' as StatusEnum
    };
}


