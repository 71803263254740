/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserUpdateDto { 
    firstName?: string;
    lastName?: string;
    role?: UserUpdateDto.RoleEnum;
    status?: UserUpdateDto.StatusEnum;
}
export namespace UserUpdateDto {
    export type RoleEnum = 'homeowner' | 'designer' | 'admin' | 'disabled' | 'manager' | 'sales' | 'customer' | 'editor' | 'viewer' | 'supervisor';
    export const RoleEnum = {
        Homeowner: 'homeowner' as RoleEnum,
        Designer: 'designer' as RoleEnum,
        Admin: 'admin' as RoleEnum,
        Disabled: 'disabled' as RoleEnum,
        Manager: 'manager' as RoleEnum,
        Sales: 'sales' as RoleEnum,
        Customer: 'customer' as RoleEnum,
        Editor: 'editor' as RoleEnum,
        Viewer: 'viewer' as RoleEnum,
        Supervisor: 'supervisor' as RoleEnum
    };
    export type StatusEnum = 'unverified' | 'active' | 'inactive' | 'suspended';
    export const StatusEnum = {
        Unverified: 'unverified' as StatusEnum,
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum,
        Suspended: 'suspended' as StatusEnum
    };
}


