/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDto { 
    firstName?: string;
    lastName?: string;
    email: string;
    /**
     * Role of user
     */
    role: UserDto.RoleEnum;
    gender?: UserDto.GenderEnum;
}
export namespace UserDto {
    export type RoleEnum = 'homeowner' | 'designer' | 'admin' | 'disabled' | 'manager' | 'sales' | 'customer' | 'editor' | 'viewer' | 'supervisor';
    export const RoleEnum = {
        Homeowner: 'homeowner' as RoleEnum,
        Designer: 'designer' as RoleEnum,
        Admin: 'admin' as RoleEnum,
        Disabled: 'disabled' as RoleEnum,
        Manager: 'manager' as RoleEnum,
        Sales: 'sales' as RoleEnum,
        Customer: 'customer' as RoleEnum,
        Editor: 'editor' as RoleEnum,
        Viewer: 'viewer' as RoleEnum,
        Supervisor: 'supervisor' as RoleEnum
    };
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}


