import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/base/base.component').then(comp => comp.BaseComponent),
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
      /**
       * Dynamically imports the LoginComponent and returns it as a Promise.
       *
       * @return {Promise<typeof LoginComponent>} A Promise that resolves to the LoginComponent class.
       */
        loadComponent: () =>
          import('./views/login/login.component').then(comp => comp.LoginComponent),
      },
      {
        path: 'register',
        /**
         * Dynamically imports the RegisterComponent and returns it as a Promise.
         *
         * @return {Promise<typeof RegisterComponent>} A Promise that resolves to the RegisterComponent class.
         */
        loadComponent: () =>
          import('./views/register/register.component').then(comp => comp.RegisterComponent),
      },
      {
        path: 'otp',
        loadComponent: () => import('./views/otp/otp.component').then(comp => comp.OTPComponent),
      },
      {
        path: 'forgot-password-otp-confirmation',
        /**
         * Dynamically imports the ForgotOTPComponent and returns it as a Promise.
         *
         * @return {Promise<typeof ForgotOTPComponent>} A Promise that resolves to the ForgotOTPComponent class.
         */
        loadComponent: () =>
          import('./views/forgot-otp/forgot-otp.component').then(comp => comp.ForgotOTPComponent),
      },
      {
        path: 'reset-password/:id',
        /**
         * Dynamically imports the ResetPasswordComponent and returns it as a Promise.
         *
         * @return {Promise<typeof ResetPasswordComponent>} A Promise that resolves to the ResetPasswordComponent class.
         */
        loadComponent: () =>
          import('./views/reset-password/reset-password.component').then(
            comp => comp.ResetPasswordComponent,
          ),
      },
      {
        path: 'forgot-password',
        /**
         * Dynamically imports the ForgotPasswordComponent and returns it as a Promise.
         *
         * @return {Promise<typeof ForgotPasswordComponent>} A Promise that resolves to the ForgotPasswordComponent class.
         */
        loadComponent: () =>
          import('./views/forgot-password/forgot-password.component').then(
            comp => comp.ForgotPasswordComponent,
          ),
      },
    ],
  },
];

export const AuthRoutingModule = RouterModule.forChild(routes);
