/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExportDto { 
    data: Array<ExportDto.DataEnum>;
    format: ExportDto.FormatEnum;
}
export namespace ExportDto {
    export type DataEnum = 'settings' | 'pages' | 'users' | 'attributeTypes' | 'products' | 'productPhotos' | 'categories' | 'wishlists' | 'deliveryMethods' | 'paymentMethods' | 'orders' | 'returns';
    export const DataEnum = {
        Settings: 'settings' as DataEnum,
        Pages: 'pages' as DataEnum,
        Users: 'users' as DataEnum,
        AttributeTypes: 'attributeTypes' as DataEnum,
        Products: 'products' as DataEnum,
        ProductPhotos: 'productPhotos' as DataEnum,
        Categories: 'categories' as DataEnum,
        Wishlists: 'wishlists' as DataEnum,
        DeliveryMethods: 'deliveryMethods' as DataEnum,
        PaymentMethods: 'paymentMethods' as DataEnum,
        Orders: 'orders' as DataEnum,
        Returns: 'returns' as DataEnum
    };
    export type FormatEnum = 'json' | 'csv';
    export const FormatEnum = {
        Json: 'json' as FormatEnum,
        Csv: 'csv' as FormatEnum
    };
}


