import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';
import * as fromAuth from './core/store/reducers';
import {EffectsModule} from '@ngrx/effects';
import { AuthEffects } from './core/store/effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, AuthRoutingModule,  StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.authReducers),
    EffectsModule.forFeature([AuthEffects]),],
  providers: [provideClientHydration()],
})
export class AuthModule {}
