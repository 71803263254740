/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaginatedLinksDocumented { 
    first?: string;
    previous?: string;
    current?: string;
    next?: string;
    last?: string;
}

