export * from './attribute-types-api.service';
import { AttributeTypesApiService } from './attribute-types-api.service';
export * from './auth-api.service';
import { AuthApiService } from './auth-api.service';
export * from './carts-api.service';
import { CartsApiService } from './carts-api.service';
export * from './categories-api.service';
import { CategoriesApiService } from './categories-api.service';
export * from './default-api.service';
import { DefaultApiService } from './default-api.service';
export * from './delivery-methods-api.service';
import { DeliveryMethodsApiService } from './delivery-methods-api.service';
export * from './import-export-api.service';
import { ImportExportApiService } from './import-export-api.service';
export * from './orders-api.service';
import { OrdersApiService } from './orders-api.service';
export * from './pages-api.service';
import { PagesApiService } from './pages-api.service';
export * from './payment-methods-api.service';
import { PaymentMethodsApiService } from './payment-methods-api.service';
export * from './product-ratings-api.service';
import { ProductRatingsApiService } from './product-ratings-api.service';
export * from './products-api.service';
import { ProductsApiService } from './products-api.service';
export * from './returns-api.service';
import { ReturnsApiService } from './returns-api.service';
export * from './settings-api.service';
import { SettingsApiService } from './settings-api.service';
export * from './users-api.service';
import { UsersApiService } from './users-api.service';
export * from './wishlists-api.service';
import { WishlistsApiService } from './wishlists-api.service';
export const APIS = [AttributeTypesApiService, AuthApiService, CartsApiService, CategoriesApiService, DefaultApiService, DeliveryMethodsApiService, ImportExportApiService, OrdersApiService, PagesApiService, PaymentMethodsApiService, ProductRatingsApiService, ProductsApiService, ReturnsApiService, SettingsApiService, UsersApiService, WishlistsApiService];
