/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Roles } from './roles';


export interface User { 
    id: number;
    firstName?: string;
    lastName?: string;
    email: string;
    role: User.RoleEnum;
    roles?: Array<Roles>;
    status: User.StatusEnum;
    gender?: User.GenderEnum;
}
export namespace User {
    export type RoleEnum = 'designer' | 'homeowner';
    export const RoleEnum = {
        Designer: 'designer' as RoleEnum,
        Homeowner: 'homeowner' as RoleEnum
    };
    export type StatusEnum = 'unverified' | 'active' | 'inactive' | 'suspended';
    export const StatusEnum = {
        Unverified: 'unverified' as StatusEnum,
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum,
        Suspended: 'suspended' as StatusEnum
    };
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}


