/**
 * SID Backend
 * SID api endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SettingCreateDto { 
    builtin?: boolean;
    name: string;
    description?: string;
    type: SettingCreateDto.TypeEnum;
    defaultValue: string;
    value?: string;
}
export namespace SettingCreateDto {
    export type TypeEnum = 'boolean' | 'number' | 'string' | 'currencyCode' | 'currenciesList' | 'country' | 'countriesList';
    export const TypeEnum = {
        Boolean: 'boolean' as TypeEnum,
        Number: 'number' as TypeEnum,
        String: 'string' as TypeEnum,
        CurrencyCode: 'currencyCode' as TypeEnum,
        CurrenciesList: 'currenciesList' as TypeEnum,
        Country: 'country' as TypeEnum,
        CountriesList: 'countriesList' as TypeEnum
    };
}


